import React, { useState } from 'react';
import Button, { ButtonProps } from '../button/Button';
import DropDownMenu, { DropDownMenuProps } from '../dropdown-menu/DropDownMenu';

interface DropDownMenuButtonProps {
    buttonProps?: Omit<ButtonProps, "children">;
    menuProps: Omit<DropDownMenuProps, "children" | "isOpen" | "onCloseRequest">;
    children: React.ReactNode;
}

export default function DropDownMenuButton(props: DropDownMenuButtonProps): React.ReactElement {
    const {
        buttonProps,
        menuProps,
        children
    } = props;

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    function handleCloseMenu(): void {
        setMenuOpen(false);
    }

    function handleOnSelection(id: string): void {
        handleCloseMenu();
        menuProps.onSelection(id);
    }

    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>): void {
        setMenuOpen(true);
        if (!!buttonProps?.onClick) {
            buttonProps.onClick(event)
        }
    }

    return (
        <DropDownMenu {...menuProps} isOpen={menuOpen} onSelection={handleOnSelection} onCloseRequest={handleCloseMenu}>
            <Button {...buttonProps} onClick={handleButtonClick}>
                {children}
            </Button>
        </DropDownMenu>
    );
}