const BOX_SHADOWS = {
  subtle: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(255, 255, 255, 0.1)", // Minimal shadow for subtle depth
  medium: "0px 4px 6px rgba(0, 0, 0, 0.5), 0px 2px 4px rgba(255, 255, 255, 0.1)", // Mid-level depth
  glow: "0px 0px 10px rgba(0, 192, 255, 0.5), 0px 0px 20px rgba(0, 192, 255, 0.3)", // Crypto-themed glowing effect
  deep: "0px 10px 15px rgba(0, 0, 0, 0.7), 0px 4px 6px rgba(255, 255, 255, 0.2)", // Deep shadow for prominent elements
  highlight: "0px 0px 20px rgba(255, 215, 0, 0.7), 0px 0px 40px rgba(255, 215, 0, 0.5)", // Golden glow for BaseCamp highlights
  inset: "inset 0px 4px 6px rgba(0, 0, 0, 0.7), inset 0px 2px 4px rgba(255, 255, 255, 0.1)", // Inset shadow for embedded elements
  adventure: "0px 0px 15px rgba(255, 87, 51, 0.5), 0px 0px 30px rgba(255, 87, 51, 0.3)", // Fiery glow, representing the adventurous community
};

const COLORS = {
  // Primary Colors (for important buttons and highlights)
  primary: '#1E40AF', // Dark Blue: Deep, trustworthy, and professional color for primary elements
  primaryLight: '#3B82F6', // Light Blue: For hover or accent effects on primary elements
  primaryDark: '#1D3A6A', // Darker Blue: For deep emphasis or focus states
  // Secondary Colors (for less critical actions, accents)
  secondary: '#F59E0B', // Golden Yellow: Optimistic and bright for secondary actions and accents
  secondaryLight: '#FBBF24', // Lighter Yellow: For hover states or softer highlights
  secondaryDark: '#F97316', // Darker Orange: Used for deep emphasis or focus on secondary actions
  // Background Colors
  background: '#121212', // Deep Charcoal: Very dark background for the body of the site
  backgroundSecondary: '#1F1F1F', // Slightly lighter dark gray: For card or section backgrounds
  backgroundDarker: '#0A0A0A', // Almost black: For footer or sections with more focus
  // Text Colors
  textPrimary: '#E5E7EB', // Light Gray: For primary text, ensuring readability on dark backgrounds
  textSecondary: '#9CA3AF', // Muted Gray: For secondary text, giving a softer contrast
  textLight: '#FFFFFF', // White: For high-contrast text in modals, headers, or dark sections
  textDark: '#1F2937', // Dark Gray: For text elements that need more subtle contrast
  // Border Colors
  borderLight: '#4B5563', // Subtle gray border for separating sections or content
  borderDark: '#2D3748', // Darker border for section divisions or inputs in dark mode
  // Accent Colors (for small elements like icons, badges, tags)
  accent1: '#10B981', // Fresh Green: For success messages or positive indicators
  accent2: '#F43F5E', // Strong Red: For warnings, errors, or critical elements
  accent3: '#3B82F6', // Blue Accent: For links, highlighted elements, or minor emphasis
  // Button Colors (Primary and Secondary Actions)
  buttonPrimary: '#1E40AF', // Dark Blue: Main button color for primary actions
  buttonPrimaryHover: '#3B82F6', // Light Blue: Hover effect for primary buttons
  buttonSecondary: '#F59E0B', // Golden Yellow: Secondary action button color
  buttonSecondaryHover: '#F97316', // Darker Orange: Hover effect for secondary buttons
  // Form Inputs (fields, selection elements, etc.)
  inputBorder: '#4B5563', // Soft gray border for inputs in dark mode
  inputFocus: '#3B82F6', // Focused input fields turn to the primary color
  inputText: '#E5E7EB', // Light gray text inside input fields for visibility
  // Gradients
  buttonGradient: 'linear-gradient(135deg, #1E40AF 0%, #3B82F6 100%)', // Blue gradient for buttons
  backgroundGradient: 'linear-gradient(135deg, #1F1F1F 0%, #121212 100%)', // Dark gradient for section backgrounds
  // Transparent Overlays
  transparent: 'rgba(0, 0, 0, 0.6)', // Dark semi-transparent overlay, ideal for modals or background dimming
};

const ZINDEX_MAP = {
  MODAL: 700,
  DROPDOWN: 600,
  NAV: 500
}

export {
  BOX_SHADOWS,
  COLORS,
  ZINDEX_MAP
};
