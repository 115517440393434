import React from 'react';
import './App.css';
import FlexColumn from './components/flex-box/FlexColumn';
import NavBar from "./nav-bar/NavBar"
import { Route, Routes } from 'react-router';
import Landing from './views/landing/Landing';
import Story from "./views/story/Story";
import { NAVIGATION } from './constants';

function App() {
  return (
    <FlexColumn style={styles.container}>
      <NavBar/>
      <Routes>
        <Route path={NAVIGATION.HOME.path} element={<Landing/>}/>
        <Route path={NAVIGATION.STORY.path} element={<Story/>}/>
      </Routes>
    </FlexColumn>
  );
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    overflow: 'hidden',
    width: '100vw',
    height: '100vh'
  }
}

export default App;
