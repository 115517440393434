import Heading from "./Heading";
import Body from "./Body";
import BodyLarge from "./BodyLarge";
import Button from "./Button";
import Caption from "./Caption";
import Footnote from "./Footnote";
import Link from "./Link";
import Quote from "./Quote";
import SubHeading from "./Subheading";
import Code from "./Code"

export const Typograhpy = {
    Heading,
    Body,
    BodyLarge,
    Button,
    Caption,
    Footnote,
    Link,
    Quote,
    SubHeading,
    Code
}