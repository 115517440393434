
import React from 'react';
import FlexRow from '../components/flex-box/FlexRow';
import { Typograhpy } from '../components/typography';
// @ts-ignore
import Logo from "../assets/BaseCampCoinLogo.png";
import HorizontalSpace from '../components/spacing/HorizontalSpace';
import { BOX_SHADOWS, COLORS, ZINDEX_MAP } from "../components/StyleConstants";
import NavMenu from './nav-menu/NavMenu';


export default function NavBar(): React.ReactElement {
    return (
        <FlexRow style={styles.container}>
            <FlexRow style={{ alignItems: 'center' }}>
                <img src={Logo} style={styles.image} alt="basecamp-logo"/>
                <HorizontalSpace width={8}/>
                <Typograhpy.SubHeading style={{ fontWeight: 700 }}>
                    Base Camp
                </Typograhpy.SubHeading>
            </FlexRow>
            <NavMenu/>
        </FlexRow>
    );
}

const styles: Record<string, React.CSSProperties> = {
    container: {
        justifyContent: 'space-between',
        boxShadow: BOX_SHADOWS.glow,
        background: COLORS.backgroundGradient, 
        padding: '8px 16px', 
        alignContent: 'center', 
        zIndex: ZINDEX_MAP.NAV, 
        position: 'relative',
        alignItems: 'center'
    },
    image: {
        width: 46,
        height: 46
    }
}