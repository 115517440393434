import React from 'react';
import FlexColumn from '../../components/flex-box/FlexColumn';
import { COLORS } from '../../components/StyleConstants';
import { Typograhpy } from '../../components/typography';
import VerticalSpace from '../../components/spacing/VerticalSpace';


export default function Landing(): React.ReactElement {
    return (
        <FlexColumn
            style={styles.container}
            className="hide-scrollbar fade-in"
        >
            <VerticalSpace height={16}/>
            <Typograhpy.SubHeading>
                Landing
            </Typograhpy.SubHeading>
        </FlexColumn>
    );
}

const styles: Record<string, React.CSSProperties> = {
    container: {
        height: '100%',
        overflowY: "scroll",
        backgroundColor: COLORS.backgroundSecondary,
        width: '100vw', 
        textAlign: 'center'
    }
}