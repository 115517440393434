import React from 'react';
import FlexColumn from '../../components/flex-box/FlexColumn';
import VerticalSpace from '../../components/spacing/VerticalSpace';
import { Typograhpy } from '../../components/typography';

export default function Story(): React.ReactElement {
    return (
        <FlexColumn className="hide-scrollbar fade-in">
            <VerticalSpace height={16}/>
            <Typograhpy.SubHeading>
                Story
            </Typograhpy.SubHeading>
        </FlexColumn>
    )
}