import React, { ButtonHTMLAttributes, DetailedHTMLProps, useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { COLORS } from '../StyleConstants';

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    defaultBackground?: React.CSSProperties['backgroundColor'];
    hoverBackground?: React.CSSProperties['backgroundColor']
};

export default function Button(props: ButtonProps): React.ReactElement {
    const {
        defaultBackground = COLORS.buttonPrimary,
        hoverBackground = COLORS.buttonPrimaryHover
    } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const hovering = useHover(buttonRef);

    const style = {
        padding: '4px 6px',
        outline: 'none',
        border: `1px solid ${COLORS.borderLight}`,
        borderRadius: 2,
        backgroundColor: defaultBackground,
        ...(hovering && {
            backgroundColor: hoverBackground
        }),
        ...(!props.disabled && {
            cursor: 'pointer'
        }),
        ...props.style,
    }

    return (
        <button {...props} ref={buttonRef} style={style}>
            {props.children}
        </button>
    )
}