import React from 'react';

interface FlexRowProps extends React.HTMLProps<HTMLDivElement> {};


export default function FlexRow(props: FlexRowProps): React.ReactElement {
    return (
        <div
            {...props}
            style={{
                ...(props.style ?? {}),
                display: "flex",
                flexDirection: "row",
            }}
        >
            {props.children}
        </div>
    )
}