import React from 'react';

interface VerticalSpaceProps {
    height: number;
}

export default function VerticalSpace(props: VerticalSpaceProps): React.ReactElement {
    const { height } = props;

    return (
        <div
            style={{ minHeight: height, height }}
        />
    )
}