import React from 'react';

interface HorizontalSpaceProps {
    width: number;
}

export default function HorizontalSpace(props: HorizontalSpaceProps): React.ReactElement {
    const { width } = props;
    return (
        <div
            style={{
                width,
                minWidth: width
            }}
        />
    )
}