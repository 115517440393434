const NAVIGATION = {
    HOME: {
        path: "/"
    },
    STORY: {
        path: "/story"
    }
}

export {
    NAVIGATION
};