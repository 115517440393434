import React from 'react';
import { COLORS } from '../StyleConstants';

interface BodyProps extends React.HTMLProps<HTMLSpanElement> {};

export default function Body(props: BodyProps): React.ReactElement {
    return (
        <span
            {...props}
            style={{
                fontFamily: "Open Sans, sans-serif",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '24px',
                color: COLORS.textPrimary,
                ...(props.style ?? {}),
            }}
        >
            {props.children}
        </span>
    )
}