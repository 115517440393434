import React from 'react';

interface FlexColumnProps extends React.HTMLProps<HTMLDivElement> {};

export default function FlexColumn(props: FlexColumnProps): React.ReactElement {
    return (
        <div
            {...props}
            style={{
                ...(props.style ?? {}),
                display: "flex",
                flexDirection: "column",
            }}
        >
            {props.children}
        </div>
    )
}