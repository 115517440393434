import React from 'react';
import FlexRow from "../../flex-box/FlexRow";
import { Typograhpy } from "../../typography";
import { COLORS } from '../../StyleConstants';
import HorizontalSpace from '../../spacing/HorizontalSpace';
import Button from '../../button/Button';

export interface DropDownMenuItemData {
    floatLeft?: React.ReactNode;
    id: string;
    title: string;
    floatRight?: React.ReactNode;
}

interface DropDownMenuItemProps {
    data: DropDownMenuItemData;
    onClick: (id: string) => void;
}

export function DropDownMenuItem(props: DropDownMenuItemProps): React.ReactElement {
    const {
        data,
        onClick
    } = props;

    function handleOnClick(_event: React.MouseEvent<HTMLButtonElement>): void {
        onClick(data.id)
    }

    return (
        <Button onClick={handleOnClick} style={{ cursor: 'pointer', border: 'none', padding: 0 }} defaultBackground='#FFFFFF' hoverBackground={'rgba(100, 100, 100, 0.6)'}>
            <FlexRow style={{ padding: '4px 8px' }}>
                {data.floatLeft}
                <HorizontalSpace width={8}/>
                <Typograhpy.Body style={{ color: COLORS.textDark }}>
                    {data.title}
                </Typograhpy.Body>
                <HorizontalSpace width={8}/>
                {data.floatRight}
            </FlexRow>
        </Button>
    );
}