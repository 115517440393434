import React from 'react';
import { DropDownMenuItem, DropDownMenuItemData } from "./drop-down-menu-item.tsx/DropDownMenuItem";
import { flip, Placement, shift, useFloating } from "@floating-ui/react";
import FlexColumn from '../flex-box/FlexColumn';
import { useOnClickOutside } from 'usehooks-ts';
import { noop } from 'lodash';
import { BOX_SHADOWS } from '../StyleConstants';

export interface DropDownMenuProps {
    items: Array<DropDownMenuItemData>;
    isOpen: boolean;
    onSelection: (id: string) => void;
    onCloseRequest?: () => void;
    children: React.ReactElement;
    placement?: Placement;
}

export default function DropDownMenu(props: DropDownMenuProps): React.ReactElement {
    const {
        items,
        children,
        isOpen,
        placement = 'bottom-start',
        onSelection,
        onCloseRequest = noop
    } = props;

    const {refs, floatingStyles} = useFloating({
        open: isOpen, 
        strategy: 'absolute', 
        middleware: [flip(), shift()], 
        placement 
    });

    useOnClickOutside(refs.floating, onCloseRequest)

    return (
        <>
            <div 
                ref={refs.setReference}
            >
                {children}
            </div>
            {isOpen && (
                <FlexColumn ref={refs.setFloating} style={{ ...floatingStyles, boxShadow: BOX_SHADOWS.deep, backgroundColor: "#FFFFFF", borderRadius: 4 }}>
                    {items.map((item) => (
                        <DropDownMenuItem
                            key={item.id}
                            data={item} 
                            onClick={() => onSelection(item.id)}
                        />
                    ))}
                </FlexColumn>
            )}
        </>
    );
}