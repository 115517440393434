import React from 'react';
import { COLORS } from '../StyleConstants';

interface SubHeadingProps extends React.HTMLProps<HTMLSpanElement> {};

export default function SubHeading(props: SubHeadingProps): React.ReactElement {
    return (
        <span
            {...props}
            style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 500,
                fontSize: 32,
                lineHeight: '40px',
                color: COLORS.textPrimary,
                ...(props.style ?? {}),
            }}
        >
            {props.children}
        </span>
    )
}