import React from 'react';
import FlexColumn from '../../components/flex-box/FlexColumn';
import { BookOutlined, HomeOutlined, MenuOutlined } from '@ant-design/icons';
import { DropDownMenuItemData } from '../../components/dropdown-menu/drop-down-menu-item.tsx/DropDownMenuItem';
import DropDownMenuButton from '../../components/dropdown-menu-button/DropDownMenuButton';
import { useNavigate } from 'react-router';
import { NAVIGATION } from "../../constants";
import { COLORS } from '../../components/StyleConstants';

enum DROPDOWN_MENU_IDS {
    HOME = "home",
    STORY = "story"
}

const DROPDOWN_MENU_ITEMS: Array<DropDownMenuItemData> = [
    {
        id: DROPDOWN_MENU_IDS.HOME,
        title: "Home",
        floatLeft: <HomeOutlined/>
    },
    {
        id: DROPDOWN_MENU_IDS.STORY,
        title: "Story",
        floatLeft: <BookOutlined/>
    }
]

export default function NavMenu(): React.ReactElement {
    const navigate = useNavigate()

    function handleSelection(id: string): void {
        switch(id) {
            case DROPDOWN_MENU_IDS.HOME:
                navigate(NAVIGATION.HOME.path);
                break;
            case DROPDOWN_MENU_IDS.STORY:
                navigate(NAVIGATION.STORY.path);
                break;
        }
    }

    return (
        <FlexColumn>
            <DropDownMenuButton menuProps={{ items: DROPDOWN_MENU_ITEMS, onSelection: handleSelection }} buttonProps={{ defaultBackground: 'transparent', hoverBackground: COLORS.transparent}}>
                <MenuOutlined style={{ color: "#FFFFFF" }}/>
            </DropDownMenuButton>
        </FlexColumn>
    )
}